import React, { Fragment } from 'react';
import { Grid, Typography, useTheme, lighten, fade } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

type Variants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'inherit';

export type WordmarkProps<D extends React.ElementType = 'span'> = {
  variant: Variants;
  component: D;
  className?: string;
  fade: number;
  color: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    light: (props: WordmarkProps) => ({
      color: fade(props.color, 0.7),
    }),
    semibold: (props: WordmarkProps) => ({
      color: props.color,
    }),
  }),
);

export const Wordmark = (props: WordmarkProps) => {
  const { variant, component, className } = props;
  const classes = useStyles(props);

  return (
    <Typography variant={variant} component={component} className={className}>
      {/* <span className={clsx(classes.light, 'light')}>The</span>
      <span> </span> */}
      <span className={clsx(classes.semibold, 'semi-bold')}>Future of Work®</span>
      <span> </span>
      <span className={clsx(classes.light, 'light')}>Funds</span>
    </Typography>
  );
};
