import React from 'react';
import clsx from 'clsx';
import imagePathGenerator from '../utils/imagePathGenerator';
import { graphql, Link } from 'gatsby';
import { Layout, Header, Footer, Section, Container } from 'gatsby-theme-core/src/components';
import { Typography, makeStyles, Theme, createStyles, Grid, Button, Hidden } from '@material-ui/core';
import { Wordmark } from '../components/wordmark';
const LOGO = require('../images/fowf-icon.png');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: 'black',
    },
    aws: {
      color: '#f19f51',
    },
    postImage: {
      width: '100%',
      alignSelf: 'center',
      boxShadow: theme.shadows[12],
    },
    inactive: {
      width: '100%',
      color: theme.palette.secondary.contrastText,
    },
    active: {
      width: '100%',
      color: theme.palette.primary.main,
    },
    disabled: {
      pointerEvents: 'none',
    },
    inline: {
      display: 'inline-block',
    },
  }),
);

const Pagination = ({ pageContext }) => {
  const classes = useStyles();
  const { previousPagePath, nextPagePath, numberOfPages, pageNumber } = pageContext;
  const pages = [...Array(numberOfPages).keys()].slice(
    pageNumber + 3 > numberOfPages ? numberOfPages - 3 : pageNumber,
    pageNumber + 3,
  );
  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? { className: `${classes.active} no-decoration ${classes.disabled}` }
      : { className: `${classes.inactive} no-decoration` };
  };

  return (
    <nav className="pagination mx-3 my-3" role="navigation">
      <Grid container item xs={12} justify="center" className="navbar navbar-menu">
        <Grid container item xs={12} sm={3} md={4} justify="center" className="py-1">
          <div className="navbar-item mx-1">
            <Link to="/blog" rel="prev" getProps={isActive}>
              <Button variant="outlined" disabled={previousPagePath ? false : true}>
                Start
              </Button>
            </Link>
          </div>
          <Hidden only={['xs', 'sm']}>
            <div className="navbar-item mx-1">
              <Link
                to={previousPagePath}
                rel="prev"
                className={clsx(previousPagePath ? null : classes.disabled, 'no-decoration')}
              >
                <Button disabled={previousPagePath ? false : true} variant="outlined">
                  Previous
                </Button>
              </Link>
            </div>
          </Hidden>
        </Grid>
        <Grid container item justify="center" xs={12} sm={6} md={4} className="py-1">
          {pages.map(i => (
            <div key={i} className="mx-1">
              <Link to={`/blog${i === 0 ? '' : '/' + (i + 1)}`} getProps={isActive}>
                <Button size="small" variant="contained">
                  {i + 1}
                </Button>
              </Link>
            </div>
          ))}
        </Grid>
        <Grid container item xs={12} sm={3} md={4} justify="center" className="py-1">
          <Hidden only={['xs', 'sm']}>
            <div className="navbar-item mx-1">
              <Link
                to={nextPagePath}
                rel="next"
                className={clsx(nextPagePath ? null : classes.disabled, 'no-decoration')}
              >
                <Button variant="outlined" disabled={nextPagePath ? false : true}>
                  Next
                </Button>
              </Link>
            </div>
          </Hidden>
          <div className="navbar-item mx-1">
            <Link to={`/blog/${numberOfPages}`} rel="prev" getProps={isActive}>
              <Button variant="outlined" disabled={nextPagePath ? false : true}>
                Last
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </nav>
  );
};

const Card = (props: any) => {
  const classes = useStyles();
  const { post } = props;
  const { frontmatter } = post;
  return (
    <>
      <Grid container className="my-4" spacing={4}>
        <Grid container item xs={12} lg={6} alignItems="center" justify="center" className="pb-1">
          <Link to={`/blog/${frontmatter.slug}`} style={{ display: 'flex' }}>
            <img
              className={classes.postImage}
              src={imagePathGenerator(frontmatter.images, frontmatter.slug)}
              alt="post image"
            />
          </Link>
        </Grid>
        <Grid container item xs={12} lg={6} direction="column" justify="flex-start" className="">
          <Grid item xs>
            <Link to={`/blog/${frontmatter.slug}`} className="no-decoration">
              <Typography variant="h2" className={clsx(classes.title, 'py-3')}>
                {frontmatter.title}
              </Typography>
            </Link>
            <Typography className="py-2 semi-bold grey-text">
              Originally Published by{' '}
              <a className={clsx('semi-bold', classes.aws)} href={frontmatter.backlink}>
                Allwork.Space
              </a>{' '}
              on {frontmatter.date}
            </Typography>
            <ul className="px-1 my-0">
              {frontmatter.excerpt.map((bullet: string, i: number) => (
                <li key={i} className="light py-1 justify">
                  {bullet}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid container item xs justify="center" alignItems="center">
            <Link to={`/blog/${frontmatter.slug}`} className="no-decoration py-4">
              <Button variant="contained">Read More</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <hr className="my-4" />
    </>
  );
};

export const Blog = ({ data, pageContext }) => {
  const posts = data.allMdx.edges;
  const classes = useStyles();
  return (
    <Layout>
      <Header classname="dark-green" logo={LOGO} />
      <Container>
        <Section spacing={6} classname="pb-0">
          <Typography variant="h2" className="uppercase my-4">
            <Wordmark className={classes.inline} variant="h2" color="#000000" />
            <span className="light">&nbsp;Blog</span>
          </Typography>
        </Section>
        <Section spacing={1} classname="pt-0">
          {posts.map(({ node }: any, i: number) => (
            // The tag below includes the markup for each post - components/common/PostCard.js
            <Card key={i} post={node} />
          ))}
        </Section>
        <Pagination pageContext={pageContext} />
      </Container>
      <Footer logo={LOGO} />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogPostQuery($limit: Int!, $skip: Int) {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          frontmatter {
            backlink
            title
            slug
            date(formatString: "MMMM DD, YYYY")
            excerpt
            images
          }
        }
      }
    }
  }
`;
